import React from "react"

const SignatureStyle = {
    borderSpacing: '20px'
}
const ImageWrapper = {
    width: '200px'
}
const Image = {
    width: '100%'
}
const TextWrapper = {
    marginLeft: '20px',
    fontFamily: 'Arial',
    lineHeight: '1.45'
}
const Name = {
    margin: '0',
    fontWeight: 'bold'
}
const Job =  {
    margin: '0px 0 10px 0',
    fontSize: '14px',
    color: "#952EF8"
}
const Link = {
    textDecoration: 'none',
    color: '#952EF8'
}
const StandardText = {
    margin: '0px 0 10px 0',
    fontWeight: 'bold',
    fontSize: '12px'
}
const Phone = {
    fontWeight: 'bold',
    fontSize: '12px',
    color: "black",
    margin: '0px 0 10px 0'
}
const PhoneLink = {
    textDecoration: 'none',
    color: "black"
}
const SmallText = {
    fontSize: '12px',
    margin: '10px 0'
}
const Socials = {
    // 
}
const Social = {
    // 
}
const SocialImg = {
    width: '25px',
    marginRight: '5px'
}

function telWithoutSpaces (number) {
    return number.replace(/\s/g, '');
}

const Signature = ({ pageContext }) => (
    <table border="0" className="signature" style={SignatureStyle}>
        <tbody border="0">
            <tr border="0">
                <td  border="0" className="image__wrapper" style={ImageWrapper}>
                    <img
                        src={`http://files.myclientisrich.com/b2a-mail/${pageContext.signatureData.imagePath}`}
                        alt={pageContext.signatureData.name}
                        style={Image}
                        width="200"
                        height="280"
                    />
                </td>
                <td border="0" className="text__wrapper" style={TextWrapper}>
                    <h2 style={Object.assign({}, Name, {color: "black"})}>{pageContext.signatureData.name}</h2>
                    <h3 style={Object.assign({}, Job, {color: "#952EF8"})}>{pageContext.signatureData.job}</h3>

                    <p style={SmallText}>
                        <strong style={StandardText}>B2A</strong><br/>
                        LABORATOIRE RHIN
                   </p>
        
                    <p style={StandardText}>
                        {pageContext.signatureData.address1}<br/>
                        {pageContext.signatureData.address2}
                    </p>
                    
                    <p style={Phone}>
                        <a href={`tel:${telWithoutSpaces(pageContext.signatureData.tel.fixe)}`} style={PhoneLink}>Tél. {pageContext.signatureData.tel.fixe}</a><br/>
                        {pageContext.signatureData.tel.fax ? (
                            <>
                                <a  href={`tel:${telWithoutSpaces(pageContext.signatureData.tel.port)}`} style={PhoneLink}>Fax : {pageContext.signatureData.tel.port}</a><br/>
                            </>
                        ) : null }
                    </p>
                    <p style={StandardText}>
                        <a href={`https://${pageContext.signatureData.url}`} style={Link}>{pageContext.signatureData.url}</a>
                    </p><br/>
                    <div style={Socials}>
                        {pageContext.signatureData.social.fb ? (
                            <>
                            <a href={pageContext.signatureData.social.fb} style={Social}>
                                <img src="http://files.myclientisrich.com/b2a-mail/fb.png" alt="Logo Facebook" width="25" height="25" style={SocialImg}/>
                            </a>                            
                            </>
                        ) : null }
                        {pageContext.signatureData.social.linkedin ? (
                            <>
                            <a href={pageContext.signatureData.social.linkedin} style={Social}>
                                <img src="http://files.myclientisrich.com/b2a-mail/linkedin.png" alt="Logo LinkedIn" width="25" height="25"style={SocialImg}/>
                            </a>                            
                            </>
                        ) : null }
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
)


export default Signature
